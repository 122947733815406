import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profile: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      state.isAuthenticated = true;
    },
    clearProfile: (state) => {
      state.profile = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setProfile, clearProfile } = authSlice.actions;

export default authSlice.reducer;
