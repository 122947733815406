import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from "./route";
import Navbar from "./components/layout/Navbar";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navbar />
        <AppRoutes />
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}
export default App;
