function Failed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.3em"
      height="1.3em"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="darkgray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 12l5.25 5l2.625-3M8 12l5.25 5L22 7m-6 0l-3.5 4"
      />
    </svg>
  );
}

export default Failed;
