import React, { useEffect, useState } from "react";
import axios from "axios";
import Delivered from "../icons/Delivered";
import Received from "../icons/Received";
import { format, isToday, isYesterday, isThisWeek } from "date-fns";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import ClipLoader from "react-spinners/ClipLoader";
import Failed from "../icons/Failed";

function SMSHistory() {
  const [myNumber, setMyNumber] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [fetching, setFetching] = useState(false);
  const [messages, setMessages] = useState([]);
  const [lastDateSent, setLastDateSent] = useState(new Date());
  const [allFetched, setAllFetched] = useState(false);
  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/sms-history`,
        {
          params: { phoneNumber, limit: 20 },
        }
      );
      setMessages(response.data);
    } catch (error) {
      toast.error("Error fetching SMS history.", { position: "top-right" });
    }
    setFetching(false);
  };

  const fetchAllHistories = async (e) => {
    if (fetching || allFetched) return;

    setFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/sms-histories`,
        {
          params: { limit: 10, dateSentBefore: lastDateSent },
        }
      );

      if (!response.data.lastDateSent) setAllFetched(true);

      setMessages((prev) => [...prev, ...response.data.messages]);
      setMyNumber(response.data.myNumber);
      setLastDateSent(response.data.lastDateSent);
    } catch (error) {
      toast.error("Error fetching SMS history.", { position: "top-right" });
    }
    setFetching(false);
  };
  const formatDateLine = (dateString) => {
    const date = new Date(dateString);

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    if (isToday(date)) return "Today";
    else if (isYesterday(date)) return "Yesterday";
    else if (isThisWeek(date)) return format(date, "EEEE");
    // Returns day of the week
    else return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderMessageStatus = (message) => {
    if (message.from === myNumber)
      switch (message.status) {
        case "delivered":
          return <Delivered />;
        case "received":
          return <Received />;
        case "failed":
          return <Failed />;
        default:
          return <Failed />;
      }
  };
  useEffect(() => {
    const container = document.querySelector(".history-box");
    if (container && messages.length > 0) {
      // Scroll to the bottom after the first load
      // container.scrollTop = container.scrollHeight - previousScrollHeight + previousScrollTop;

      // Scroll to the top after loading
      container.scrollTop = previousScrollHeight;
      setPreviousScrollHeight(container.scrollHeight);
    }
  }, [messages]); // This will run every time the messages array is updated

  useEffect(() => {
    const container = document.querySelector(".history-box");

    const handleScroll = () => {
      // Check if the current scroll position is bottom or top
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight &&
        !fetching &&
        !allFetched
      ) {
        fetchAllHistories();
      }
    };
    container.addEventListener("scroll", handleScroll);

    return () => container.removeEventListener("scroll", handleScroll);
  }, [fetching, allFetched]);

  useEffect(() => {
    fetchAllHistories();
  }, []);

  let lastMessageDate = "";

  return (
    <div className="container mx-auto">
      <div className="flex flex-col h-full bg-gray-50 border rounded-lg shadow-lg">
        {/* Header */}
        {/* <form
          className="p-4 rounded-t-lg flex gap-4 items-center bg-gray-300"
          onSubmit={handleSubmit}
        >
          <h3 className="text-lg font-semibold">PhoneNumber:</h3>
          <PhoneInput
            country={"us"}
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
            inputClass="px-3 py-2 border rounded-lg"
            inputStyle={{ height: "100%" }}
            containerStyle={{ width: "auto", height: 40 }}
            buttonClass="bg-white border-r-0 rounded-l-lg"
            dropdownClass="bg-white rounded-lg"
            searchClass="border px-3 py-2 rounded-lg"
            enableSearch={true}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            isValid={(inputNumber, country, countries) => {
              return countries.some((country) => {
                return (
                  startsWith(inputNumber, country.dialCode) ||
                  startsWith(country.dialCode, inputNumber)
                );
              });
            }}
          />
          <button
            type="submit"
            disabled={fetching}
            class="bg-blue-700 text-white p-2 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
          >
            See history
          </button>
        </form> */}
        <div className="history-box overflow-y-auto my-10 h-[80vh]">
          {/* Messages Area */}
          <div className="flex-grow p-4 mx-[10%]">
            <ul className="space-y-4">
              {messages.length === 0 && (
                <p className="font-bold text-2xl text-center">No messages.</p>
              )}
              {messages.map((message, index) => {
                const messageDate = new Date(
                  message.dateSent
                ).toLocaleDateString();
                const showDateLine = messageDate !== lastMessageDate;
                lastMessageDate = messageDate;

                return (
                  <React.Fragment key={index}>
                    {showDateLine && (
                      <li className="text-center text-xs text-gray-500 my-4">
                        <hr className="border-t border-gray-300" />
                        <span className="px-2">
                          {formatDateLine(message.dateSent)}
                        </span>
                      </li>
                    )}
                    {message.to === myNumber ? (
                      <p className="text-start text-[12px] opacity-75">
                        {message.from}
                      </p>
                    ) : (
                      <p className="text-end text-[12px] opacity-75">
                        {`to ${message.to}`}
                      </p>
                    )}
                    <li
                      className={`flex ${
                        message.to === myNumber
                          ? "justify-start"
                          : "justify-end"
                      }`}
                    >
                      <div
                        className={`max-w-xs p-3 rounded-lg shadow ${
                          message.to === myNumber
                            ? "bg-white text-gray-800"
                            : "bg-blue-500 text-white"
                        }`}
                      >
                        <p className="text-sm break-words">{message.body}</p>
                        <div className="flex mt-2 gap-2 items-center justify-end">
                          <p className="text-xs text-right opacity-75">
                            {new Date(message.dateSent).toLocaleTimeString()}
                          </p>
                          <p className="text-xs mt-1 text-right">
                            {renderMessageStatus(message)}
                          </p>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>

          <div className="flex items-center justify-center">
            <ClipLoader
              color="blue"
              loading={fetching}
              aria-label="Fetching SMS history"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SMSHistory;
