import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducer/authSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Configuration object for redux-persist
const persistConfig = {
    key: 'root',
    storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, authReducer);

// Create the store with the persisted reducer
export const store = configureStore({
    reducer: {
        auth: persistedReducer,
    },
});

// Create a persistor
export const persistor = persistStore(store);

export default store;
