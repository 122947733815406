import React, { useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import startsWith from "lodash.startswith";

const SMSForm = () => {
  const [sending, setSending] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSending(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-sms`,
        {
          phoneNumber,
          message,
        }
      );
      if (response.data.success)
        toast.success("SMS sent successfully!", { position: "top-right" });
      else toast.error("Unable to send SMS.", { position: "top-right" });
      setPhoneNumber("");
      setMessage("");
    } catch (error) {
      toast.error("Failed to send SMS.", { position: "top-right" });
    }
    setSending(false);
  };

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-2xl font-bold text-center mb-4">Send SMS</h2>
          <form id="smsForm" className="space-y-4" onSubmit={handleSubmit}>
            <div className="relative">
              <label
                for="phone"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Phone number:
              </label>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                containerClass="w-full"
                inputClass="px-3 py-2 border rounded-lg"
                inputStyle={{ width: "100%" }}
                buttonClass="bg-white border-r-0 rounded-l-lg"
                dropdownClass="bg-white rounded-lg"
                searchClass="border px-3 py-2 rounded-lg"
                enableSearch={true}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                isValid={(inputNumber, country, countries) => {
                  return countries.some((country) => {
                    return (
                      startsWith(inputNumber, country.dialCode) ||
                      startsWith(country.dialCode, inputNumber)
                    );
                  });
                }}
              />
            </div>
            <div>
              <label
                for="message"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Message:
              </label>
              <textarea
                id="message"
                maxLength={160}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                rows="4"
                required
                className="w-full border border-gray-300 rounded-lg p-3 outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
              >
                <ScaleLoader
                  color="white"
                  loading={sending}
                  height={13}
                  aria-label="Sending SMS"
                  data-testid="loader"
                />
                {!sending && "Send SMS"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SMSForm;
