import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/auth/Login";
import Profile from "../components/Profile";
import SendSMS from "../components/SMS/SendSMS";
import PrivateRoute from "../components/route/PrivateRoute";
import SMSHistory from "../components/SMS/SMSHistory";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/send-sms"
          element={
            <PrivateRoute>
              <SendSMS />
            </PrivateRoute>
          }
        />
        <Route
          path="/sms-history"
          element={
            <PrivateRoute>
              <SMSHistory />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
