import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Profile() {
  const profile = useSelector((state) => state.auth.profile);
  return (
    <div className="container m-auto">
      <div className="flex flex-col gap-3 mt-20 items-center justify-center">
        <img className="w-10" src={profile.picture} alt="user image" />
        <h3>User Logged in</h3>
        <p>Name: {profile.name}</p>
        <p>Email Address: {profile.email}</p>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Profile;
