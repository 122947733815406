import { useDispatch, useSelector } from "react-redux";
import { clearProfile } from "../../reducer/authSlice";
import { googleLogout } from "@react-oauth/google";

function Navbar() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    dispatch(clearProfile());
  };

  return (
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-white text-sm py-3 dark:bg-neutral-800">
      <nav className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between">
        <a
          className="flex-none font-semibold text-xl text-black focus:outline-none focus:opacity-80 dark:text-white"
          href="/"
          aria-label="TryBoostLocal"
        >
          TryBoostLocal
        </a>
        <div className="flex flex-row items-center gap-5 mt-5 sm:justify-end sm:mt-0 sm:ps-5">
          <a
            className="font-medium text-gray-600 hover:text-gray-400 focus:outline-none focus:text-gray-400 dark:text-neutral-400 dark:hover:text-neutral-500 dark:focus:text-neutral-500"
            href="/send-sms"
          >
            SMS
          </a>
          <a
            className="font-medium text-gray-600 hover:text-gray-400 focus:outline-none focus:text-gray-400 dark:text-neutral-400 dark:hover:text-neutral-500 dark:focus:text-neutral-500"
            href="/sms-history"
          >
            History
          </a>
          <a
            className="font-medium text-gray-600 hover:text-gray-400 focus:outline-none focus:text-gray-400 dark:text-neutral-400 dark:hover:text-neutral-500 dark:focus:text-neutral-500"
            href="/profile"
          >
            Account
          </a>
          {isAuthenticated && (
            <button
              type="button"
              className="px-5 py-2.5 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={logOut}
            >
              Log out
            </button>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
