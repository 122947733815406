import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import axios from "axios";
import SignInWithGoogle from "../social-buttons/SignInWithGoogle";
import { setProfile } from "../../reducer/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => toast.error("Login failed.", { position: "top-right" }),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const userEmail = res.data.email;

          if (userEmail.endsWith("@tryboostlocal.com")) {
            toast.success("Logged in successfully!", { position: "top-right" });
            dispatch(setProfile(res.data));
            navigate("/profile"); // Redirect to dashboard or any authorized page
          } else {
            toast.error("Invalid email.");
            // Optionally, you can also log out the user or clear the token
            setUser(null);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="container m-auto">
      <p className="font-bold text-3xl text-center mt-10">
        Welcome to TryBoostLocal
      </p>
      <div className="flex flex-col justify-center items-center h-screen gap-2">
        <SignInWithGoogle login={login} />
      </div>
    </div>
  );
}
export default Login;
